// using for downtime categories (pie chart)
export const downtimeCategories = {
  all: 'All',
  electrical: 'Electrical',
  mechanical: 'Mechanical',
  other: 'Other',
  scheduledLoss: 'ScheduledLoss',
  unassigned: 'Unassigned',
  ignoreDowntime: 'IgnoreDowntime',
}

// using for downtime categories displaying string
export const downtimeCategoriesStr = {
  all: 'All',
  electrical: 'Electrical',
  mechanical: 'Mechanical',
  other: 'Other',
  scheduledLoss: 'Scheduled Loss',
  unassigned: 'Unassigned',
  ignoreDowntime: 'Ignore Downtime',
}

// using for downtime tag items (bore downtime table)
export const tagItems = {
  electrical: 'Electrical',
  mechanical: 'Mechanical',
  sl: 'SL',
  operator: 'Operator',
  ulf: 'ULF',
  ulo: 'ULO',
  other: 'Other',
  unassigned: 'Unassigned',
  na: 'NA',
}

// using for downtime tags tooltips (bore downtime table)
export const downtimeTagsTooltips = {
  ULF: {
    name: 'Unscheduled Loss Failures',
  },
  ULO: {
    name: 'Unscheduled Loss Other',
  },
  SL: {
    name: 'Scheduled Loss',
  },
  Unassigned: {
    name: 'Unassigned',
  },
  Electrical: {
    name: 'Electrical',
  },
  Mechanical: {
    name: 'Mechanical',
  },
  Other: {
    name: 'Other',
  },
  Operator: {
    name: 'Operator',
  },
  NSP: {
    name: 'No Scheduled Production',
  },
  OS: {
    name: 'Operating Standby',
  },
  OD: {
    name: 'Operating Delay',
  },
  OT: {
    name: 'Operating Time'
  },
  NA: {
    name: 'Not Applicable (Ignored invalid Downtime record)'
  }
}