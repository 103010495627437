import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import { type PropsWithChildren, createContext, useEffect, useMemo } from 'react'
import { appInsightsInstance, appInsightsPlugin, trackException } from './app-insights.instance'
import { type AppInsightsContextType } from './app-insights.types'
import { useAccount, useMsal } from '@azure/msal-react'

const AppInsightsContext = createContext<AppInsightsContextType | undefined>(undefined)

export const AppInsightsProvider = ({ children }: PropsWithChildren) => {
  const { accounts } = useMsal()
  const account = useAccount(accounts[0])

  useEffect(() => {
    if (accounts.length > 0 && account && appInsightsInstance) {
      appInsightsInstance.clearAuthenticatedUserContext()
      appInsightsInstance.setAuthenticatedUserContext(account.username)
      if (appInsightsInstance?.context?.user) {
        appInsightsInstance.context.user.accountId = account.username
      }

      appInsightsInstance.addTelemetryInitializer((env) => {
        env.data = {
          ...env.data,
          accountId: account.username,
        }
      })
    }
  }, [account])

  const ctx = useMemo(() => {
    return {
      trackException,
    } as const
  }, [])

  return (
    <AppInsightsContext.Provider value={ctx}>
      <AppInsightsErrorBoundary onError={() => <></>} appInsights={appInsightsPlugin}>
        <>{children}</>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  )
}
