import { Box, Drawer } from '@mui/material'
import { Outlet } from 'react-router-dom'
import App from '../../App'
import Navbar from '../../components/navbar/Navbar'
import { Topbar } from '../../components/topbar'
import { useAtom } from 'jotai'
import { navigationDrawerAtom } from '../../store/atoms/navigation-drawer'
import theme from '../../theme'

export const MainLayout = () => {
  const [open, setOpen] = useAtom(navigationDrawerAtom)

  const paddingLeft = open ? '240px' : '0'

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        transition: 'backdrop-filter 0.3s ease',
        width: open ? '100%' : 'auto',
        '@media (max-width: 960px)': {
          width: '100%',
        },
      }}
    >
      <Topbar />
      <Box
        sx={{
          pl: { md: paddingLeft },
        }}
        height={'100vh'}
      >
        <Drawer
          variant="persistent"
          sx={{
            '& .MuiDrawer-paper': { border: 'none' },
            zIndex: '9999',
            '& .MuiPaper-root': {
              width: { xs: '100%', sm: '100%', md: '240px', lg: '240px', xl: '240px' },
            },
            background: theme.palette.common.white,
          }}
          onClose={() => setOpen((state) => !state)}
          open={open}
          anchor="left"
          data-testid="navigation-drawer"
        >
          <Navbar />
        </Drawer>
        <App />
        <Outlet />
      </Box>
    </Box>
  )
}