import { ReactPlugin as ApplicationInsightsReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web'
import { createBrowserHistory } from 'history'
import { ErrorInfo } from 'react'

export const appInsightsPlugin = new ApplicationInsightsReactPlugin()
export let appInsightsInstance: ApplicationInsights | undefined

appInsightsInstance = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
    connectionString: process.env.REACT_APP_APPLICATION_INSIGHTS,
    extensions: [appInsightsPlugin],
    extensionConfig: {
      [appInsightsPlugin.identifier]: { history: createBrowserHistory() },
    },
    disableFetchTracking: false,
  },
})

appInsightsInstance.loadAppInsights()

export const trackException = (
  level: SeverityLevel,
  args: {
    error: Error
    info: ErrorInfo
    componentName?: string
    message?: string
  },
) => {
  appInsightsInstance?.trackException({
    exception: args.error,
    severityLevel: level,
    properties: {
      info: args.info,
      componentName: args.componentName,
      message: args.message,
    },
  })
}
