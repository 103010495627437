import { Box, List } from '@mui/material'
import { lazy } from 'react'
import theme from '../../theme'

const DewateringNavigation = lazy(async () => {
  const mod = await import('.')

  return {
    default: mod.Navbar,
  }
})

export default function Navbar() {
  return (
    <Box
      id="navbar"
      data-testid="navbar"
      sx={{ height: '100%', width: { xs: '100%', sm: '100%', md: '240px', lg: '240px', xl: '240px' }}}
      borderRight="1px solid"
      borderColor={theme.palette.lightGray?.dark}
    >
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          p: 0,
          pt: '64px',
        }}
      >
        <DewateringNavigation />
      </List>
    </Box>
  )
}
